
































































































































































































































import SaisirDate from '../components/SaisirDate.vue'
import Vue from 'vue'
import axios from 'axios'

import { dbMaintenance, stringFromDate, cleControle } from '../plugins/variables'
const ensembles = require('@/assets/ensembles.json')
import { tMachine } from '../store/types'

declare const navigator: any
declare const Camera: any

interface Reparation {
  rep_id: number //clé primaire
  mt_id: number
  rep_texte: string // texte libre (remèdes, réparations)
  rep_salarie: string[]
  rep_date: string | null
  rep_temps: number
  rep_km: number | null
  rep_duree_trajet: number | null
  rep_pieces: string // champ libre
  rep_societe: string // varchar(200) CHARACTER SET utf8 COLLATE utf8_general_ci DEFAULT NULL,
  rep_soc_numbon: string // varchar(200) CHARACTER SET utf8 COLLATE utf8_general_ci DEFAULT NULL,
  rep_soc_temps: number // double DEFAULT NULL,
  rep_soc_date: string // date DEFAULT NULL,
}

class tMaintenance {
  mt_id: number
  mt_date: string
  site_id: number
  chantier: string
  mac_id: number
  mt_immat: string
  mt_ensemble: string
  mt_sous_ensemble: string
  mt_horametre: number
  mt_code_defaut: string
  mt_nb_crevaisons: string
  mt_statut: string // non lancé, en cours ,attente , terminé , cloturé
  mt_delai: string // J=jour, 1S=1semaine, 2S = 2 semaines, 4S = 3semaines, A=arrêt prog, 3M= 3 mois, 4M = 4 mois, 6M=6 mois, 9M = 9 mois, 12M = 12 mois
  mt_redacteur: string
  mt_destinataire: string
  mt_revision: string
  mt_panne_texte: string // texte libre Symptômes, panne
  mt_photos: string[]
  reparations: Reparation[]
  envoyer_serveur: boolean
  constructor() {
    this.mt_id = 0
    this.mt_date = ''
    this.site_id = 0
    this.chantier = ''
    this.mac_id = 0
    this.mt_immat = ''
    this.mt_ensemble = ''
    this.mt_sous_ensemble = ''
    this.mt_horametre = 0
    this.mt_code_defaut = ''
    this.mt_nb_crevaisons = ''
    this.mt_statut = ''
    this.mt_delai = ''
    this.mt_redacteur = ''
    this.mt_destinataire = ''
    this.mt_revision = ''
    this.mt_panne_texte = ''
    this.mt_photos = []
    this.reparations = []
    this.envoyer_serveur = false
  }
  copier(i: tMaintenance) {
    this.mt_id = i.mt_id
    this.mt_date = i.mt_date
    this.site_id = i.site_id
    this.chantier = i.chantier
    this.mac_id = i.mac_id
    this.mt_immat = i.mt_immat
    this.mt_ensemble = i.mt_ensemble
    this.mt_sous_ensemble = i.mt_sous_ensemble
    this.mt_horametre = i.mt_horametre
    this.mt_code_defaut = i.mt_code_defaut
    this.mt_nb_crevaisons = i.mt_nb_crevaisons
    this.mt_statut = i.mt_statut
    this.mt_delai = i.mt_delai
    this.mt_redacteur = i.mt_redacteur
    this.mt_destinataire = i.mt_destinataire
    this.mt_revision = i.mt_revision
    this.mt_panne_texte = i.mt_panne_texte
    if (Array.isArray(i.mt_photos)) {
      this.mt_photos = i.mt_photos.slice()
    } else {
      this.mt_photos = []
    }
    this.reparations = i.reparations.slice()
    this.envoyer_serveur = i.envoyer_serveur
  }
}
interface tSalarieOptions {
  value: number
  label: string
}

export default Vue.extend({
  name: 'Maintenance',
  components: { SaisirDate },
  data() {
    return {
      unsubscribe: null as any,
      ajaxLoading: false,
      dataPhoto: '', //variable temporaire
      resolvePhoto: null as any,
      rejectPhoto: null as any,
      utilisateur: '',
      maintenance: new tMaintenance(),
      reparation: {} as Reparation,
      dialogueMaintenance: false,
      formValidMaintenance: false,
      dialogueReparation: false,
      formValidReparation: false,
      maintenancesSauvegardees: [] as tMaintenance[],
      sites_options: [
        { value: 1, text: 'BELVAL' },
        { value: 2, text: 'DIFFERDANGE' },
        { value: 10, text: 'FEY' },
        { value: 6, text: 'GAND ' },
        { value: 5, text: 'GANDRANGE' },
        { value: 11, text: 'GENT' },
        { value: 3, text: 'HAGONDANGE' },
        { value: 7, text: 'LE CREUSOT' },
        { value: 4, text: 'LEXY' },
        { value: 8, text: 'RIVE DE GIER' },
        { value: 9, text: 'ROMBAS' },
        { value: 12, text: 'AUTRE' }
      ],
      situation: 'en cours',
      situation_options: [
        { value: 'non lancé', text: 'Non lancé' },
        { value: 'en cours', text: 'En cours' },
        { value: 'attente', text: 'Attente' },
        { value: 'terminé', text: 'Terminé' },
        { value: 'cloturé', text: 'Cloturé' }
      ],
      situation_libelle: {
        'non lancé': 'Non lancé',
        'en cours': 'En cours',
        attente: 'Attente',
        terminé: 'Terminé',
        cloturé: 'Cloturé'
      },
      delai_options: [
        { value: 'J', text: 'jour' },
        { value: '1S', text: '1 semaine' },
        { value: '2S', text: '2 semaines' },
        { value: '4S', text: '3 semaines' },
        { value: 'A', text: 'arrêt prog' },
        { value: '3M', text: '3 mois' },
        { value: '4M', text: '4 mois' },
        { value: '6M', text: '6 mois' },
        { value: '9M', text: '9 mois' },
        { value: '12M', text: '12 mois' }
      ],
      salarie_options: [] as tSalarieOptions[],
      presents_options: [] as string[],
      gravite_options: ['Faible', 'Elevée'],
      saisieObligatoire: [(v: any): string | boolean => !!v || 'Saisie obligatoire'],
      url: this.$store.state.ajaxurl,
      chantiers: [
        'SCM',
        'CHANTIER',
        'NW',
        'PAM',
        'DECOUPE MANUEL',
        'ACIERIE',
        'DEMOLITION',
        'AGENCE',
        'PONT 7',
        'PARC 3',
        'ATELIER',
        'TRI',
        'BOULAGE',
        'CRIBLAGE',
        'DECHARGEMENT',
        'EXTERIEUR'
      ],
      machines: [] as tMachine[],
      ensembles: ensembles.sort(),
      nouvelle: false, //si nouvelle Maintenance
      emails: [],
      emails_options: [] as string[]
    }
  },
  methods: {
    async chargerDB() {
      const db = await dbMaintenance()
      let salaries = await db.getAll('salaries')
      for (let rep of salaries) {
        this.salarie_options.push({ value: rep.ut_id, label: `${rep.ut_nom} ${rep.ut_prenom}` })
      }
      this.salarie_options.sort((a, b) => (a.label < b.label ? -1 : 1))
      this.presents_options = this.salarie_options.map((S) => S.label)
      this.emails_options = salaries.filter((S) => S.ut_email > '').map((S) => S.ut_email)
      let machines = await db.getAll('machines')
      machines.sort((a: any, b: any) => {
        if (a.mac_categorie == b.mac_categorie) {
          if (a.mac_desi < b.mac_desi) {
            return -1
          }
          return 1
        }
        if (a.mac_categorie < b.mac_categorie) {
          return -1
        }
        return 1
      })
      this.machines = machines.map((mac) => {
        return {
          mac_id: mac.mac_id,
          mac_desi: mac.mac_desi,
          mac_categorie: mac.mac_categorie,
          mac_libelle: mac.mac_categorie + ' ' + mac.mac_desi,
          mac_serie: mac.mac_serie
        }
      })
    },
    onSituation() {
      this.actualiser()
    },
    actualiserTout() {
      this.$store.dispatch('chargerTables')
      this.actualiser()
    },
    async actualiser() {
      /*const db = await dbMaintenance()
      this.maintenancesSauvegardees = await db.getAllFromIndex('maintenance', 'situation', this.situation)
      for (let j = 0; j < this.maintenancesSauvegardees.length; j++) {
        const ch_id = this.maintenancesSauvegardees[j].ch_id
        const nom = this.chantiers.find((v) => v.ch_id == ch_id)
        this.maintenancesSauvegardees[j].chantier = nom?.ch_nom ?? ''
      }
      db.close()*/
      this.maintenancesSauvegardees = []
      const url = this.$store.state.ajaxurl + 'ajax_appli.php'
      axios
        .post(url, {
          action: 'LECTURE_MAINTENANCES',
          cle: cleControle(),
          situation: this.situation,
          uuid: localStorage.getItem('maintenance-uuid')
        })
        .then((response) => {
          if (response.data.maintenances) {
            for (const m of response.data.maintenances) {
              const i = this.maintenancesSauvegardees.findIndex((mt) => mt.mt_id == m.mt_id)
              const nm = new tMaintenance()
              nm.copier(m)
              nm.envoyer_serveur = false

              if (i == -1) {
                this.maintenancesSauvegardees.push(nm)
              } else {
                this.maintenancesSauvegardees[i] = nm
              }
            }
          }
        })
    },
    nouvelleMaintenance() {
      this.emails = []
      const dte = new Date()
      const sdate = stringFromDate(dte)
      this.nouvelle = true
      this.maintenance = new tMaintenance()
      this.maintenance.mt_id = new Date().getTime()
      this.maintenance.mt_date = sdate
      this.maintenance.site_id = 2
      this.maintenance.chantier = this.chantiers[0]
      this.maintenance.mt_statut = 'non lancé'
      this.maintenance.envoyer_serveur = true
      this.dialogueMaintenance = true
    },
    editer(mt_id: number) {
      this.emails = []
      const i = this.maintenancesSauvegardees.findIndex((C) => C.mt_id == mt_id)
      if (i > -1) {
        this.nouvelle = false
        this.maintenance.copier(this.maintenancesSauvegardees[i])
        this.dialogueMaintenance = true
        const url = this.$store.state.ajaxurl + 'ajax_appli.php'
        axios
          .post(url, {
            action: 'PHOTOS_MAINTENANCE',
            cle: cleControle(),
            mt_id: this.maintenancesSauvegardees[i].mt_id
            //ch_id: this.ch_id,
          })
          .then((response) => {
            if (response.data.photos) {
              this.maintenance.mt_photos = JSON.parse(response.data.photos)
            }
          })
      }
    },
    async supprimer(mt_id: string) {
      const rep = confirm('Confirmer la suppression')
      if (rep) {
        /*const db = await dbMaintenance()
        await db.delete('maintenance', mt_id)
        db.close()*/
        this.actualiser()
      }
    },
    async enregistrerMaintenance() {
      this.maintenance.envoyer_serveur = true
      /*const db = await dbMaintenance()
      await db.put('maintenance', enre)
      db.close()*/
      this.dialogueMaintenance = false
      const i = this.maintenancesSauvegardees.findIndex((m) => m.mt_id == this.maintenance.mt_id)
      if (i > -1) {
        this.maintenancesSauvegardees[i].copier(this.maintenance)
      } else {
        const nm = new tMaintenance()
        nm.copier(this.maintenance)
        this.maintenancesSauvegardees.push(nm)
      }
      this.envoyerAuServeur()
    },
    nouvelleIntervention(mt_id: number) {
      this.emails = []
      this.reparation = {
        mt_id: mt_id,
        rep_id: new Date().getTime(),
        rep_texte: '',
        rep_salarie: [],
        rep_date: stringFromDate(new Date()),
        rep_temps: 0,
        rep_km: null,
        rep_duree_trajet: null,
        rep_pieces: '',
        rep_societe: '',
        rep_soc_numbon: '',
        rep_soc_temps: 0,
        rep_soc_date: ''
      }
      this.dialogueReparation = true
    },
    editerReparation(mt_id: number, rep_id: number) {
      this.emails = []
      const i = this.maintenancesSauvegardees.findIndex((C) => C.mt_id == mt_id)
      if (i > -1) {
        this.maintenance = this.maintenancesSauvegardees[i]
        const j = this.maintenance.reparations.findIndex((r) => r.rep_id == rep_id)
        if (j != -1) {
          const r = this.maintenance.reparations[j]
          this.reparation = {
            mt_id: r.mt_id,
            rep_id: r.rep_id,
            rep_texte: r.rep_texte,
            rep_salarie: r.rep_salarie.slice(),
            rep_date: r.rep_date,
            rep_temps: r.rep_temps,
            rep_km: r.rep_km,
            rep_duree_trajet: r.rep_duree_trajet,
            rep_pieces: r.rep_pieces,
            rep_societe: r.rep_societe,
            rep_soc_numbon: r.rep_soc_numbon,
            rep_soc_temps: r.rep_soc_temps,
            rep_soc_date: r.rep_soc_date
          }
          this.dialogueReparation = true
        }
      }
    },
    enregistrerReparation() {
      const i = this.maintenancesSauvegardees.findIndex((m) => m.mt_id == this.reparation.mt_id)
      if (i > -1) {
        this.maintenance = this.maintenancesSauvegardees[i]
        this.maintenance.envoyer_serveur = true
        const j = this.maintenance.reparations.findIndex((r) => r.rep_id == this.reparation.rep_id)
        if (j == -1) {
          this.maintenance.reparations.push(this.reparation)
        } else {
          this.maintenance.reparations[j] = { ...this.reparation }
        }
      }
      this.dialogueReparation = false
      this.enregistrerMaintenance()
    },
    envoyerAuServeur() {
      const url = this.$store.state.ajaxurl + 'ajax_appli.php'
      if (this.maintenancesSauvegardees && Array.isArray(this.maintenancesSauvegardees)) {
        axios
          .post(url, {
            action: 'ENREGISTRER_MAINTENANCES',
            cle: cleControle(),
            uuid: localStorage.getItem('maintenance-uuid'),
            maintenances: this.maintenancesSauvegardees.filter((m) => m.envoyer_serveur)
          })
          .then((response: any) => {
            axios.post(this.$store.state.ajaxurl + 'ajax_email.php', {
              maintenances: this.maintenancesSauvegardees.filter((m) => m.envoyer_serveur).map((m) => m.mt_id),
              emails: this.emails
            })
            this.actualiser()
          })
      }
    },
    chargerImage(event: any) {
      //exécuté depuis la promise prendrePhoto en simulant un clique sur le bouton #chargerImageBtn
      var files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        this.rejectPhoto(false) //fin de la promise
        return
      }
      const file = files[0]
      const reader = new FileReader()
      reader.onload = (e: any) => {
        // lecture terminée
        this.dataPhoto = e.target.result
        this.resolvePhoto(true) //fin de la promise
      }
      reader.readAsDataURL(file) // lance la lecture
    },
    prendrePhoto(): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        this.resolvePhoto = resolve
        this.rejectPhoto = reject
        if (navigator.camera) {
          //plugin Cardova
          navigator.camera.getPicture(
            (imageData: any) => {
              this.dataPhoto = 'data:image/jpeg;base64,' + imageData
              resolve(true)
            },
            () => {
              this.dataPhoto = ''
              reject(false)
            },
            {
              quality: 25,
              destinationType: Camera.DestinationType.DATA_URL,
              correctOrientation: true,
              targetWidth: 1000,
              targetHeight: 1000
            }
          )
        } else {
          //méthode classique
          const btnPhoto = document.getElementById('chargerImageBtn')
          if (btnPhoto) {
            btnPhoto.click()
          } else {
            reject(false)
          }
        }
      })
    },
    nouvellePhoto() {
      this.prendrePhoto().then((rep) => {
        if (rep) {
          this.maintenance.mt_photos.push(this.dataPhoto)
        }
      })
    },
    onMachine() {
      const rep = this.machines.find((M) => M.mac_id == this.maintenance.mac_id)?.mac_serie
      this.maintenance.mt_immat = rep ?? ''
    },
    desiMachine(mac_id: number) {
      const rep = this.machines.find((M) => M.mac_id == mac_id)?.mac_desi
      return rep ?? ''
    },
    nomSalarie(ut_id: number) {
      const i = this.salarie_options.findIndex((s) => s.value == ut_id)
      if (i > -1) return this.salarie_options[i].label
      return ''
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'majJour':
          this.actualiser()
          break
        case 'majH24':
          this.actualiser()
          break
        case 'majMachines':
          this.chargerDB()
          break
        case 'majSalaries':
          this.chargerDB()
          break
      }
    })
    this.chargerDB()
    this.actualiser()
  },
  beforeDestroy() {
    this.unsubscribe()
    this.unsubscribe = null
  }
})
