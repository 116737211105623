

















import Vue from 'vue'

export default Vue.extend({
  name: 'SaisirDate',
  data: () => ({
    menu: false,
    sdate: '',
    datefr: '',
    erreur: false
  }),
  created() {
    this.sdate = this.value
    if (this.value) {
      const [year, month, day] = this.value.split('-')
      if (year && month && day) {
        this.datefr = `${day}/${month}/${year}`
      }
    } else {
      this.datefr = ''
    }
  },
  watch: {
    value() {
      this.sdate = this.value
      this.toFrench()
    }
  },
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 290
    },
    icon: {
      type: String,
      default: 'mdi-calendar'
    }
  },
  methods: {
    toFrench() {
      if (this.sdate == null) {
        return ''
      }
      const [year, month, day] = this.sdate.split('-')
      if (year && month && day) {
        this.datefr = `${day}/${month}/${year}`
      }
    },
    onDate(dte: string) {
      if (!dte) return
      const [day, month, year] = dte.split('/')
      if (year && month && day) {
        const s = `${year}-${month}-${day}`
        const d = Date.parse(s)
        if (!isNaN(d)) {
          this.sdate = s
          this.datefr = dte
          this.$emit('input', this.sdate)
          this.erreur = false
        } else {
          this.erreur = true
        }
      }
    },
    onPicker() {
      this.menu = false
      this.toFrench()
      this.$emit('input', this.sdate)
    }
  }
})
